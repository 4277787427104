"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function business2point(args) {
    if (args.type === 0) {
        return 0;
    }
    else {
        args.tax_amount = args.tax_amount || 0;
        args.employees_num = args.employees_num || 0;
        args.share_ratio = args.share_ratio || 0;
        var max = args.tax_amount > args.employees_num ? args.tax_amount : args.employees_num;
        var point = Math.floor(max / 10) * 10 * args.share_ratio / 100;
        // 得分向下取整
        point = Math.floor(point);
        return point > 120 ? 120 : point;
    }
}
exports.default = business2point;
