"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// 学历积分
function education2point(education) {
    if (education === void 0) { education = null; }
    var educationPoint = {
        below_high_school: 0,
        college: 50,
        bachelor: 60,
        bachelor_degree: 90,
        master: 100,
        doctor: 110
    };
    return educationPoint[education] || 0;
}
exports.default = education2point;
