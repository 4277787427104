"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// 表彰奖励
function reward2point(level) {
    if (level === void 0) { level = null; }
    var reward = {
        level_1: 30,
        level_2: 60,
        level_3: 110,
        level_0: 0,
    };
    return reward[level] || 0;
}
exports.default = reward2point;
