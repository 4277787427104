"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// 高倍社保积分
function hign_social_security2point(level) {
    if (level === void 0) { level = null; }
    var hign_social_security = {
        level_1: 0,
        level_2: 25,
        level_3: 50,
        level_4: 100,
        level_5: 120,
        level_0: 0,
    };
    return hign_social_security[level] || 0;
}
exports.default = hign_social_security2point;
