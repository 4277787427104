"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function professional_skills2point(level) {
    if (level === void 0) { level = null; }
    var professional_skills = {
        level_5: 15,
        level_4: 30,
        level_3: 60,
        level_2: 100,
        level_1: 140,
        level_0: 0
    };
    if ((level.level == 'level_2' || level.level == 'level_1') && !level.level_4_5_needed) {
        return 0;
    }
    return professional_skills[level.level] || 0;
}
exports.default = professional_skills2point;
