"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function hard_word2point(args) {
    var port_work = args.port_work || 0;
    var sanitation_work = args.sanitation_work || 0;
    var port_work_point = 0;
    if (port_work >= 5) {
        port_work_point = port_work * 4;
    }
    var sanitation_work_point = 0;
    if (sanitation_work >= 5 && sanitation_work <= 10) {
        sanitation_work_point = sanitation_work * 2;
    }
    else if (sanitation_work > 10) {
        sanitation_work_point = 20;
    }
    return port_work_point + sanitation_work_point;
}
exports.default = hard_word2point;
