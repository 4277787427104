"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var age_ts_1 = require("./rules/age.ts");
var education_ts_1 = require("./rules/education.ts");
var professional_skills_ts_1 = require("./rules/professional_skills.ts");
var social_security_ts_1 = require("./rules/social_security.ts");
var hign_social_security_ts_1 = require("./rules/hign_social_security.ts");
var reward_ts_1 = require("./rules/reward.ts");
var business_ts_1 = require("./rules/business.ts");
var innovative_entrepreneurial_talent_ts_1 = require("./rules/innovative_entrepreneurial_talent.ts");
var needed_professional_ts_1 = require("./rules/needed_professional.ts");
var hard_word_ts_1 = require("./rules/hard_word.ts");
var violation_of_law_ts_1 = require("./rules/violation_of_law.ts");
var getPoint = {
    age: age_ts_1.default,
    education: education_ts_1.default,
    professional_skills: professional_skills_ts_1.default,
    social_security: social_security_ts_1.default,
    hign_social_security: hign_social_security_ts_1.default,
    reward: reward_ts_1.default,
    business: business_ts_1.default,
    innovative_entrepreneurial_talent: innovative_entrepreneurial_talent_ts_1.default,
    needed_professional: needed_professional_ts_1.default,
    hard_word: hard_word_ts_1.default,
    violation_of_law: violation_of_law_ts_1.default
};
// 积分计算器类
var Point = /** @class */ (function () {
    function Point() {
        var _this = this;
        this.age = null;
        this.education = null;
        this.professional_skills = null;
        this.social_security = null;
        this.hign_social_security = null;
        this.reward = null;
        this.business = null;
        this.innovative_entrepreneurial_talent = null;
        this.needed_professional = null;
        this.hard_word = null;
        this.violation_of_law = null;
        this.items = [
            'age',
            'education',
            'professional_skills',
            'social_security',
            'hign_social_security',
            'reward',
            'business',
            'innovative_entrepreneurial_talent',
            'needed_professional',
            'hard_word',
            'violation_of_law'
        ];
        this.temp = {};
        this.age_point = 0;
        this.education_point = 0;
        this.professional_skills_point = 0;
        this.social_security_point = 0;
        this.hign_social_security_point = 0;
        this.reward_point = 0;
        this.business_point = 0;
        this.innovative_entrepreneurial_talent_point = 0;
        this.needed_professional_point = 0;
        this.hard_word_point = 0;
        this.violation_of_law_point = 0;
        this.total_point = 0;
        // 初始化劫持数据绑定
        this.items.forEach(function (key) {
            Object.defineProperty(_this, key, {
                get: function () {
                    return this.temp[key];
                },
                set: function (newVal) {
                    var _this = this;
                    this.temp[key] = newVal;
                    this[key + '_point'] = getPoint[key](newVal);
                    // 计算总分
                    var total_point = 0;
                    this.items.forEach(function (key) {
                        total_point = _this[key + '_point'] + total_point;
                    });
                    // 学历和技能得分只能取其一，去除较少的
                    total_point = total_point - (this.education_point > this.professional_skills_point ? this.professional_skills_point : this.education_point);
                    this.total_point = total_point < 0 ? 0 : total_point;
                }
            });
        });
    }
    return Point;
}());
exports.default = Point;
