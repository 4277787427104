"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// 年龄积分
function age2point(age) {
    if (age === void 0) { age = 0; }
    var agePoint = {
        44: 29,
        45: 27,
        46: 25,
        47: 23,
        48: 21,
        49: 19,
        50: 17,
        51: 15,
        52: 13,
        53: 11,
        54: 9,
        55: 7
    };
    // 18-43
    if (age >= 18 && age <= 43) {
        return 30;
    }
    // 56-60
    else if (age >= 56 && age <= 60) {
        return 5;
    }
    else {
        return agePoint[age] || 0;
    }
}
exports.default = age2point;
